import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DwtMatchModes } from '@port-line-up/shared/util';
import { TableModule } from 'primeng/table';

@Component({
    selector: 'plu-dwt-filter',
    standalone: true,
    imports: [TableModule],
    templateUrl: './dwt-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DwtFilterComponent {
    @Input({required: true}) field!: string;    
    DwtMatchModes = DwtMatchModes;
}
