import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'plu-order',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent {
    @Input() set numberOfItems(numberOfItems: number) {
        this.items = [...Array(numberOfItems).keys()].map((x) => x + 1).reverse();
    }
    @Input() activeItem: number | null = null;

    items: number[] = [];
}
