import { Injectable } from '@angular/core';
import { VesselStatus } from '../enums';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class VesselStatusService {
    private vesselStatuses: Map<string, LookupItem> = new Map([
        [VesselStatus.Expected, { id: VesselStatus.Expected, displayText: 'Expected' }],
        [VesselStatus.Arrived, { id: VesselStatus.Arrived, displayText: 'Arrived' }],
        [VesselStatus.Berthed, { id: VesselStatus.Berthed, displayText: 'Berthed' }],
        [VesselStatus.Sailed, { id: VesselStatus.Sailed, displayText: 'Sailed' }],
        [VesselStatus.OperationsInProgress, { id: VesselStatus.OperationsInProgress, displayText: 'Operations in progress' }],
        [VesselStatus.OperationCompleted, { id: VesselStatus.OperationCompleted, displayText: 'Operation completed' }],
    ]);

    get() {
        return this.vesselStatuses;
    }

    getById(status: VesselStatus): LookupItem | undefined {
        return this.vesselStatuses.get(status);
    }

    getDisplayTextById(status: VesselStatus): string {
        return this.vesselStatuses.get(status)?.displayText ?? '';
    }
}
