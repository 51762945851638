import { FilterMatchMode } from "primeng/api";

export enum CargoMatchMode {
  CargoType = 'cargoType',
  CargoRate = 'cargoRate',
  CargoRateUOM = 'cargoRateUOM',
  Quantity = 'quantity',
  QuantityUOM = 'quantityUOM'
}


export enum CustomMatchMode {
  EmptyDate = 'emptyDate',
  ColumnFilterItemIn = 'columnFilterItemIn',
  PartyFilterRule = 'partyFilterRule',
}


export const DwtMatchModes = [
  {
    label: 'Less than',
    value: FilterMatchMode.LESS_THAN
  },
  {
    label: 'Less than or equal',
    value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
  },
  {
    label: 'Greater than',
    value: FilterMatchMode.GREATER_THAN
  },
  {
    label: 'Greater than or equal',
    value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
  },
  {
    label: 'Equals',
    value: FilterMatchMode.EQUALS
  },
];

export const DateMatchModes = [
  {
    label: 'Date is',
    value: FilterMatchMode.DATE_IS
  },
  {
    label: 'Date is not',
    value: FilterMatchMode.DATE_IS_NOT,
  },
  {
    label: 'Date is before',
    value: FilterMatchMode.DATE_BEFORE
  },
  {
    label: 'Date is after',
    value: FilterMatchMode.DATE_AFTER,
  },
  {
    label: 'Empty',
    value: CustomMatchMode.EmptyDate
  },
];