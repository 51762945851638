import { PortCallExcelHeaders } from '@port-line-up/shared/util';
import { ExcelHeadersResolver } from '../../excel-headers-resolver';

export class ExcelHeadersDefaultResolver extends ExcelHeadersResolver {
    override canResolve(): boolean {
        return true;
    }

    override resolve(): PortCallExcelHeaders[] {
        return [
            'Priority',
            'Vessel Name',
            'Imo Number',
            'Vessel Type',
            'Voyage Number',
            'Status',
            'Port Name',
            'Port Code',
            'Terminal',
            'Berth',
            'Berth Order',
            'ETA',
            'ETA IHS',
            'ATB',
            'ATB IHS',
            'ATA',
            'ATA IHS',
            'ETB',
            'ATD',
            'ATD IHS',
            'ECC',
            'ACC',
            'ETC',
            'ATC',
            'ETD',
            'Operation',
            'Notes',
            'Dwt',
            'Delay',  
            'Next Destination',          
            'Cargo',
            'Cargo Description',
            'Cargo Rate',
            'UOM (CR)',
            'Quantity',
            'UOM (QT)'
        ];
    }
}
