export class PortCallSave {
    id: string | null = null;
    priority = 0;
    voyageNumber = '';
    gacJobNumber = '';
    vesselId: string | null = null;
    vesselStatusId: string | null = null;
    portId: string | null = null;
    chartererCode: string | null = null;
    charterer: string | null = null;
    shipperCode: string | null = null;
    shipper: string | null = null;
    shippingAgentCode: string | null = null;
    shippingAgent: string | null = null;
    terminalId: string | null = null;
    berthId: string | null = null;
    operationId: string | null = null;
    eta: string | null = null;
    ata: string | null = null;
    etb: string | null = null;
    atb: string | null = null;
    ecc: string | null = null;
    acc: string | null = null;
    etc: string | null = null;
    atc: string | null = null;
    etd: string | null = null;
    atd: string | null = null;
    notes = '';
    nextDestinationNote: string | null = null;
    cargos: PortCallCargoSave[] = [];
    cargosDeletedIds: string[] = [];
}

export class PortCallCargoSave {
    id: string | null = null;
    cargoTypeId: string | null = null;
    cargoRate: number | null = null;
    cargoDescription = '';
    cargoRateUomId: string | null = null;
    cargoQuantityUomId: string | null = null;
    cargoQuantity: number | null = null;
}