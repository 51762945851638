import { User } from '@port-line-up/shared/data-access';
import { ExcelUploadMappingDataResolver } from '../../excel-upload-mapping-data-resolver';
import { PropertyNameExcelHeaderMapping } from '@port-line-up/shared/util';

export class ExcelUploadMappingDataAdminResolver extends ExcelUploadMappingDataResolver {
    constructor(private user: User) {
        super();
    }

    override canResolve(): boolean {
        return this.user.isGlobalAdmin();
    }

    override resolve(): PropertyNameExcelHeaderMapping[] {
        return [
            { propertyName: 'imoNumber', propertyNameTitle: 'Imo Number' },
            { propertyName: 'vesselName', propertyNameTitle: 'Vessel Name' },
            { propertyName: 'voyageNumber', propertyNameTitle: 'Voyage Number' },
            { propertyName: 'status', propertyNameTitle: 'Status' },
            { propertyName: 'terminal', propertyNameTitle: 'Terminal' },
            { propertyName: 'berth', propertyNameTitle: 'Berth' },
            { propertyName: 'eta', propertyNameTitle: 'ETA' },
            { propertyName: 'ata', propertyNameTitle: 'ATA' },
            { propertyName: 'etb', propertyNameTitle: 'ETB' },
            { propertyName: 'atb', propertyNameTitle: 'ATB' },
            { propertyName: 'etc', propertyNameTitle: 'ETC' },
            { propertyName: 'atc', propertyNameTitle: 'ATC' },
            { propertyName: 'etd', propertyNameTitle: 'ETD' },
            { propertyName: 'atd', propertyNameTitle: 'ATD' },
            { propertyName: 'ecc', propertyNameTitle: 'ECC' },
            { propertyName: 'acc', propertyNameTitle: 'ACC' },
            { propertyName: 'cargo', propertyNameTitle: 'Cargo' },
            { propertyName: 'cargoDescription', propertyNameTitle: 'Cargo Description' },
            { propertyName: 'cargoRate', propertyNameTitle: 'Cargo Rate' },
            { propertyName: 'cargoRateUOM', propertyNameTitle: 'UOM (CR)' },
            { propertyName: 'operation', propertyNameTitle: 'Operation' },
            { propertyName: 'quantity', propertyNameTitle: 'Quantity' },
            { propertyName: 'quantityUOM', propertyNameTitle: 'UOM (QT)' },
            { propertyName: 'notes', propertyNameTitle: 'Notes' },
            { propertyName: 'charterer', propertyNameTitle: 'Charterer' },
            { propertyName: 'chartererCode', propertyNameTitle: 'Charterer Code' },
            { propertyName: 'shipper', propertyNameTitle: 'Shipper' },
            { propertyName: 'shipperCode', propertyNameTitle: 'Shipper Code' },
            { propertyName: 'shippingAgent', propertyNameTitle: 'Shipping Agent' },
            { propertyName: 'shippingAgentCode', propertyNameTitle: 'Shipping Agent Code' },
            { propertyName: 'nextDestinationNote', propertyNameTitle: 'Next Destination' },
        ];
    }
}
