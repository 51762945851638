export const environment = {
    production: true,
	baseUrl: 'https://portlineup.gac.com/',
    baseApiUrl: 'https://apis.gac.com/external/portlineup/v1/api',
    signalRUrl: 'https://portlineup.gac.com/push/',
    b2cSettings: {
        clientId: '281bfee5-362e-40b5-8692-167ee73a4655',
        signInAuthority: 'https://login.microsoftonline.com/6e9db74c-11b7-459e-b199-9f044662d260',
        scopes: ['api://281bfee5-362e-40b5-8692-167ee73a4655/access_as_user']
    },
    ocpApimSubscriptionKey: 'fe681aec43ae40208e74e599353bd724',
    mapbox: {
        accessToken: 'pk.eyJ1IjoiZ3JvdXBpdCIsImEiOiJjbGV3dTRtOTAwazA0M3JvNHhzbGdxOHVkIn0.4-wcba5ifxEWSahwcol-3w'
    },
    powerBI: {
        embedUrl: 'https://app.powerbi.com/reportEmbed'
    }
}
