import { Injectable } from '@angular/core';
import { BerthDelayService, PortCall } from '@port-line-up/shared/data-access';
import { PortCallPresentation } from '../models';
import { LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class PortCallPresentationFactoryService {

    constructor(private berthDelayService: BerthDelayService) {}

    create(portCalls: PortCall[]): PortCallPresentation[] {
        return portCalls.map((p) => this.createSingle(p));
    }

    private createSingle(portCall: PortCall): PortCallPresentation {
        const response = {} as PortCallPresentation;
        response.id = portCall.id;
        response.imoNumber = portCall.imoNumber;
        response.priority = portCall.priority;
        response.berthOrder = portCall.berthOrder;
        response.vesselName = portCall.vesselName.trim() ? portCall.vesselName.trim() : `IMO ${portCall.imoNumber}`;
        response.vesselId = portCall.vesselId ?? '';
        response.voyageNumber = portCall.voyageNumber;
        response.gacJobNumber = portCall.gacJobNumber;
        response.vesselStatus = portCall.vesselStatus;
        response.vesselStatusId = portCall.vesselStatusId;
        response.vesselType = portCall.vesselType;
        response.portName = portCall.portName;
        response.terminalName = portCall.terminalName;
        response.terminalId = portCall.terminalId;
        response.berthName = portCall.berthName;
        response.berthId = portCall.berthId;
        response.charterer = portCall.charterer;
        response.chartererCode = portCall.chartererCode;
        response.shipper = portCall.shipper;
        response.shipperCode = portCall.shipperCode;
        response.shippingAgent = portCall.shippingAgent;
        response.shippingAgentCode = portCall.shippingAgentCode;
        response.cargoType = portCall.cargos?.length > 0 ? portCall.cargos.map(cargo =>cargo.cargoTypeId && cargo.cargoType ? LookupItem.create(cargo.cargoTypeId, cargo.cargoType) : null) : [null];
        response.cargoDescription = portCall.cargos.map(cargo =>cargo.cargoDescription);
        response.cargoRate = portCall.cargos.map(cargo =>cargo.cargoRate);
        response.cargoRateUomName = portCall.cargos.map(cargo =>cargo.cargoRateUom);
        response.cargoQuantity = portCall.cargos.map(cargo =>cargo.cargoQuantity);
        response.cargoQuantityUomName = portCall.cargos.map(cargo =>cargo.cargoQuantityUom);
        response.cargoQuantityUomId = portCall.cargos.map(cargo =>cargo.cargoQuantityUomId);
        response.operationId = portCall.operationId;
        response.operationName = portCall.operationName;
        response.eta = portCall.eta ? new Date(portCall.eta) : null;
        response.etaihs = portCall.etaihs ? new Date(portCall.etaihs) : null;
        response.ataihs = portCall.ataihs ? new Date(portCall.ataihs) : null;
        response.ata = portCall.ata ? new Date(portCall.ata) : null;
        response.etb = portCall.etb ? new Date(portCall.etb) : null;
        response.atb = portCall.atb ? new Date(portCall.atb) : null;
        response.ecc = portCall.ecc ? new Date(portCall.ecc) : null;
        response.acc = portCall.acc ? new Date(portCall.acc) : null;
        response.etc = portCall.etc ? new Date(portCall.etc) : null;
        response.atc = portCall.atc ? new Date(portCall.atc) : null;
        response.etd = portCall.etd ? new Date(portCall.etd) : null;
        response.atd = portCall.atd ? new Date(portCall.atd) : null;
        response.notes = portCall.notes;
        response.nextDestinationNote = portCall.nextDestinationNote;
        response.latitude = portCall.latitude;
        response.longitude = portCall.longitude;
        response.heading = portCall.heading;
        response.dwt = portCall.dwt;
        response.berthDelay = this.berthDelayService.getBerthDelay(portCall);
        response.speed = portCall.speed;
        response.vesselStatusIHS = portCall.vesselStatusIHS
        response.ihsVesselLocationId = portCall.ihsVesselLocationId;
        return response;
    }
}
