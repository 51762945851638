import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_ENVIRONMENT, LookupItem } from '@port-line-up/shared/util';

@Injectable({ providedIn: 'root' })
export class VesselStatusHttpService {
    private readonly baseUrl = `${this.appEnvironment.baseApiUrl}/vesselStatus`;

    constructor(private http: HttpClient, @Inject(APP_ENVIRONMENT) private appEnvironment: any) {}

    getVesselStatuses(): Observable<LookupItem[]> {
        return this.http.get<LookupItem[]>(`${this.baseUrl}/lookup`);
    }
}
