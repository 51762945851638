import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterChipsServiceBase, GridFilter, GridFilterChip, GridFilterReactiveService, PresentationTableFilterIds } from '@port-line-up/shared/util';
import { debounceTime, skip } from "rxjs";

@Injectable()
export class FilterChipsService extends FilterChipsServiceBase {
    private gridFilters: GridFilter | null = null;

    constructor(
        private gridFilterReactiveService: GridFilterReactiveService,
    ) {
        super();
        this.subscribeToFilterChange();
    }

    private subscribeToFilterChange(): void {
        this.gridFilterReactiveService.portCallFilters$.pipe(takeUntilDestroyed(), skip(1), debounceTime(200)).subscribe((gridFilters) => {
            this.gridFilters = gridFilters;
            this.createFilterChips();
        });
    }

    private createFilterChips(): void {
        if (!this.gridFilters) {
            return;
        }

        const gridFilterChips: GridFilterChip[] = [];
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.vesselStatusId, 'Statuses', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.terminalId, 'Terminals', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.berthId, 'Berths', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.cargoType, 'Cargos', gridFilterChips);
        this.createDateFilterChipItem(this.gridFilters, PresentationTableFilterIds.ata, 'ETA/ATA', gridFilterChips);
        this.createDateFilterChipItem(this.gridFilters, PresentationTableFilterIds.atb, 'ETB/ATB', gridFilterChips);
        this.createDateFilterChipItem(this.gridFilters, PresentationTableFilterIds.acc, 'ECC/ACC', gridFilterChips);
        this.createDateFilterChipItem(this.gridFilters, PresentationTableFilterIds.atc, 'ETC/ATC', gridFilterChips);
        this.createDateFilterChipItem(this.gridFilters, PresentationTableFilterIds.atd, 'ETD/ATD', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.vesselName, 'Vessel Name', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.vesselType, 'Vessel Type', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.voyageNumber, 'Voyage Number', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.cargoDescription, 'Cargo Description', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.notes, 'Notes', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.operationId, 'Operations', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.portName, 'Port Name', gridFilterChips);
        this.createDwtFilterChipItem(this.gridFilters, PresentationTableFilterIds.dwt, 'Dwt', gridFilterChips);
        this.createBerthDelayFilterChipItem(this.gridFilters, PresentationTableFilterIds.berthDelay, 'Delay (d/h)', gridFilterChips);
        this.createFilterChipItem(this.gridFilters, PresentationTableFilterIds.nextDestinationNote, 'Next Destination', gridFilterChips);
        this.createPartyFilterChipItem(this.gridFilters, PresentationTableFilterIds.party)
        this.filterChipsSubject.next(gridFilterChips);
    }
}
