import { User } from '@port-line-up/shared/data-access';
import { PortCallExcelHeaders } from '@port-line-up/shared/util';
import { ExcelHeadersResolver } from '../../excel-headers-resolver';

export class ExcelHeadersForTemplateAdminResolver extends ExcelHeadersResolver {
    constructor(private user: User) {super()}

    override canResolve(): boolean {
        return this.user.isGlobalAdmin();
    }

    override resolve(): PortCallExcelHeaders[] {
        return [
            'Imo Number',
            'Vessel Name',
            "Vessel Type",
            'Status',
            'Port Code',
            'Charterer',
            'Charterer Code',
            'Shipper',
            'Shipper Code',
            'Shipping Agent',
            'Shipping Agent Code',
            'Terminal',
            'Berth',
            'ETA',
            'ETA IHS',
            'ATA',
            'ETB',
            'ATB',
            'ECC',
            'ACC',
            'ETC',
            'ATC',
            'ETD',
            'ATD',
            'Operation',
            'Notes',
            'Dwt',
            'Delay',
            'Cargo',
            'Next Destination',
            'Cargo Description',
            'Cargo Rate',
            'UOM (CR)',
            'Quantity',
            'UOM (QT)'
        ];
    }
}

