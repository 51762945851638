import { inject } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseStore, BaseState, PortCall, baseInitialState } from '@port-line-up/shared/data-access';
import { AddedDataService, ChangedDataService, DeletedDataService, SailedDataService } from './data-update-services';
import { AddedPortCalls, ChangedPortCalls, DeletedPortCalls, SailedPortCalls } from './models/changed-port-calls';
import { ApplyChangesStatus } from './models/apply-changes-status';

export interface DataUpdateState extends BaseState {
    portCalls: PortCall[];
    changedPortCalls: ChangedPortCalls;
    addedPortCalls: AddedPortCalls;
    deletedPortCalls: DeletedPortCalls;
    sailedPortCallChanges: SailedPortCalls;
    applyChanges: { status: ApplyChangesStatus };
    lastUpdate: Date;
}

export const dataUpdateInitialState = () => ({
    portCalls: [],
    changedPortCalls: new Map(),
    addedPortCalls: new Map(),
    deletedPortCalls: new Map(),
    sailedPortCallChanges: new Map(),
    applyChanges: { status: ApplyChangesStatus.NothingToApply },
    lastUpdate: new Date(),
    ...baseInitialState
} as DataUpdateState);

export class DataUpdateStore<T extends DataUpdateState> extends BaseStore<T> {
    private changedDataService = inject(ChangedDataService);
    private addedDataService = inject(AddedDataService);
    private deletedDataService = inject(DeletedDataService);
    private sailedDataService = inject(SailedDataService);

    constructor(initialState: T) {
        super(initialState);
    }

    readonly lastUpdate$: Observable<Date> = this.select((state) => state.lastUpdate);
    readonly changedPortCalls$: Observable<ChangedPortCalls> = this.select((state) => state.changedPortCalls);
    readonly addedPortCalls$: Observable<AddedPortCalls> = this.select((state) => state.addedPortCalls);
    readonly deletedPortCalls$: Observable<DeletedPortCalls> = this.select((state) => state.deletedPortCalls);
    readonly sailedPortCallChanges$: Observable<SailedPortCalls> = this.select((state) => state.sailedPortCallChanges);
    readonly applyChanges$: Observable<{ status: ApplyChangesStatus }> = this.select((state) => state.applyChanges);

    replaceEditedPortCalls = this.updater((state, editedPortCalls: PortCall[]) => ({
        ...state,
        portCalls: this.insertEditedPortCalls(state.portCalls, editedPortCalls),
    }));

    addAddedPortCalls = this.updater((state, addedPortCalls: PortCall[]) => ({
        ...state,
        portCalls: [...state.portCalls, ...addedPortCalls],
    }));

    setAddedPortCalls = this.updater((state, addedPortCalls: PortCall[]) => ({
        ...state,
        addedPortCalls: this.updateAddedPortCalls(new Map(state.addedPortCalls), addedPortCalls),
    }));

    removeAddedPortCallsAfter = this.updater((state, seconds: number) => ({
        ...state,
        addedPortCalls: this.addedDataService.remove(new Map(state.addedPortCalls), seconds),
    }));

    setChangedPortCalls = this.updater((state, editedPortCalls: PortCall[]) => ({
        ...state,
        changedPortCalls: this.updateChangedPortCalls(new Map(state.changedPortCalls), state.portCalls, editedPortCalls),
    }));

    removeChangedPortCallsAfter = this.updater((state, seconds: number) => ({
        ...state,
        changedPortCalls: this.changedDataService.remove(new Map(state.changedPortCalls), seconds),
    }));

    applyChanges = this.updater((state, status: ApplyChangesStatus) => ({
        ...state,
        applyChanges: { status: status },
    }));

    setLastUpdate = this.updater((state) => ({
        ...state,
        lastUpdate: new Date(),
    }));

    deleteDeletedPortCalls = this.updater((state, portCallIds: string[]) => ({
        ...state,
        portCalls: state.portCalls.filter((x) => !portCallIds.includes(x.id)),
    }));

    setDeletedPortCalls = this.updater((state, deletedPortCallsIds: string[]) => ({
        ...state,
        deletedPortCalls: this.updateDeletedPortCalls(new Map(state.deletedPortCalls), deletedPortCallsIds),
    }));

    removeDeletedPortCallsAfter = this.updater((state, seconds: number) => ({
        ...state,
        deletedPortCalls: this.deletedDataService.remove(new Map(state.deletedPortCalls), seconds),
    }));

    deleteSailedPortCalls = this.updater((state, portCallIds: string[]) => ({
        ...state,
        portCalls: state.portCalls.filter((x) => !portCallIds.includes(x.id)),
    }));

    setSailedPortCallChanges = this.updater((state, sailedPortCallsIds: string[]) => ({
        ...state,
        sailedPortCallChanges: this.updateSailedPortCallChanges(new Map(state.sailedPortCallChanges), sailedPortCallsIds),
    }));

    removeSailedPortCallChangesAfter = this.updater((state, seconds: number) => ({
        ...state,
        sailedPortCallChanges: this.sailedDataService.remove(new Map(state.sailedPortCallChanges), seconds),
    }));

    private insertEditedPortCalls(portCalls: PortCall[], editedPortCalls: PortCall[]): PortCall[] {
        const map = new Map<string, PortCall>();
        editedPortCalls.forEach((p) => map.set(p.id, p));

        return portCalls.map((p) => {
            const editedPortCall = map.get(p.id);
            return editedPortCall ? editedPortCall : p;
        });
    }

    private updateChangedPortCalls(changedPortCalls: ChangedPortCalls, portCalls: PortCall[], editedPortCalls: PortCall[]): ChangedPortCalls {
        const editedPortCallsById = new Map<string, PortCall>();
        editedPortCalls.forEach((p) => editedPortCallsById.set(p.id, p));

        const propertiesToCompare: (keyof PortCall)[] = [
            'priority',
            'berthOrder',
            'vesselId',
            'voyageNumber',
            'vesselStatusId',
            'gacJobNumber',
            'unlocode',
            'terminalId',
            'chartererCode',
            'shippingAgentCode',
            'shipperCode',
            'berthId',
            'operationId',
            'cargos',
            'eta',
            'etaihs',
            'ata',
            'ataihs',
            'etb',
            'atb',
            'ecc',
            'acc',
            'etc',
            'atc',
            'etd',
            'atd',
            'notes',
            'atbihs',
            'atdihs',
        ];

        for (const portCall of portCalls) {
            const editedPortCall = editedPortCallsById.get(portCall.id);
            if (!editedPortCall) continue;
            propertiesToCompare.forEach((propertyName) => {
                if (this.isChanged(editedPortCall[propertyName], portCall[propertyName])) {
                    this.changedDataService.add(portCall.id, propertyName, changedPortCalls);
                }
            });
        }

        return changedPortCalls;
    }

    private isChanged<T>(newPropertyValue: T, oldPropertyValue: T): boolean {
        return JSON.stringify(newPropertyValue) !== JSON.stringify(oldPropertyValue);
    }

    private updateAddedPortCalls(addedMap: AddedPortCalls, addedPortCalls: PortCall[]): AddedPortCalls {
        for (const portCall of addedPortCalls) {
            this.addedDataService.add(portCall.id, addedMap);
        }

        return addedMap;
    }

    private updateDeletedPortCalls(deletedMap: DeletedPortCalls, deletedPortCallsIds: string[]): DeletedPortCalls {
        for (const portCallId of deletedPortCallsIds) {
            this.deletedDataService.add(portCallId, deletedMap);
        }

        return deletedMap;
    }

    private updateSailedPortCallChanges(sailedMap: SailedPortCalls, sailedPortCallsIds: string[]): DeletedPortCalls {
        for (const portCallId of sailedPortCallsIds) {
            this.sailedDataService.add(portCallId, sailedMap);
        }

        return sailedMap;
    }
}
