import { PortCallExcelHeaders } from '@port-line-up/shared/util';
import { ExcelHeadersResolver } from '../../excel-headers-resolver';

export class ExcelHeadersForTemplateDefaultResolver extends ExcelHeadersResolver {
    override canResolve(): boolean {
        return true;
    }

    override resolve(): PortCallExcelHeaders[] {
        return [
            'Imo Number',
            'Vessel Name',
            'Vessel Type',
            'Status',
            'Port Code',
            'Terminal',
            'Berth',
            'ETA',
            'ETA IHS',
            'ATA',
            'ETB',
            'ATB',
            'ECC',
            'ACC',
            'ETC',
            'ATC',
            'ETD',
            'ATD',
            'Operation',
            'Notes',
            'Dwt',
            'Delay',
            'Next Destination',
            'Cargo',
            'Cargo Description',
            'Cargo Rate',
            'UOM (CR)',
            'Quantity',
            'UOM (QT)',
        ];
    }
}
