import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { PortCallPresentation } from '../models';

@Injectable({providedIn: 'root'})
export class BerthOrderService {
    constructor(private datePipe: DatePipe) { }

    getBerthOrderTooltip(item: PortCallPresentation, portCalls: PortCallPresentation[]): string {
        const filteredPortCalls = portCalls.filter(x => x.berthId && x.berthId === item.berthId).sort((a,b) => a.berthOrder - b.berthOrder).slice(0, 5);

        const stringBuilder = [];

        for (const [index, portCall] of filteredPortCalls.entries()) {
            const etbDate = this.datePipe.transform(portCall.etb);
            const etdDate = this.datePipe.transform(portCall.etd);
            stringBuilder.push(
                `${index+1}. ${portCall.vesselName} - ETB ${etbDate ?? 'Unknown'} - ETD ${etdDate ?? 'Unknown'}`)
        }
        return stringBuilder.join('\n');
    }

    getPortCallsWithSameBerth(portCall: PortCallPresentation, portCalls: PortCallPresentation[]): PortCallPresentation[] {
        return portCalls.filter(x => x.berthId && x.berthId === portCall.berthId).sort((a,b) => {
            return b.berthOrder - a.berthOrder})
    }
}
